let stripe: any | null = null;
let elements: any | null = null;
let paymentElement: any | null = null;

type ContainerProps = {
    pspContainerId: string;
    sdkContainerId: string;
};

interface ElementsOptions {
    amount?: number;
    appearance?: AppearanceOptions;
    captureMethod?: string;
    currency?: string;
    fonts?: string[];
    loader?: string;
    locale?: string;
    mode?: string;
    paymentMethodConfiguration?: string;
    paymentMethodCreation?: string;
    paymentMethodOptions?: object;
    paymentMethodTypes?: string[];
    setupFutureUsage?: string;
}

interface PaymentElementOptions {
    applePay?: object;
    business?: object;
    defaultValues?: object;
    fields?: object;
    layout?: string;
    paymentMethodOrder?: string[];
    readOnly?: boolean;
    terms?: object;
    wallets?: object;
}

interface AppearanceOptions {
    theme?: string;
    labels?: string;
    rules?: object;
}

export const requiredScripts = ["https://js.stripe.com/v3/"];

/**
 * Initializes Stripe.js and creates an instance of the Payment Element (without an Intent).
 * @param details - The configuration options for Stripe.
 * @returns A promise that resolves when the Payment Element is mounted.
 */
export async function render(details: any): Promise<void> {
    try {
        // Creates a new div element with the specified ID and prepends it
        // to the SDK container element.
        const target = document.createElement("div");
        target.setAttribute("id", details.pspContainerId);
        document.getElementById(details.sdkContainerId)?.prepend(target);

        // Retrieve the Stripe Elements component configuration.
        const allowedPaymentMethodTypes = getAllowedPaymentMethodTypes(`${details.paymentMethodType.toLowerCase()}_${details.currency.toLowerCase()}`);
        const config = {
            elementsOptions: getElementsOptions(details, allowedPaymentMethodTypes),
            paymentElementOptions: getPaymentElementOptions(allowedPaymentMethodTypes)
        };

        // Create an instance of Stripe Payment Element and mount it to the DOM.
        stripe = (window as any).Stripe(details.apiKey, { stripeAccount: details.accountId });
        elements = stripe.elements(config.elementsOptions);

        paymentElement = elements.create("payment", config.paymentElementOptions);
        await paymentElement.mount(`#${details.pspContainerId}`);

    } catch (error) {
        console.error("Failed to initialize Stripe Payment Element:", error);
        throw new Error("Failed to initialize Stripe Payment Element.");
    }
}

/**
 * Tokenizes the payment details.
 * @param details - The details required for tokenization.
 * @returns A promise that resolves to the tokenized payment method.
 */
export async function tokenize(details: any): Promise<any> {
    if (!paymentElement) {
        throw new Error("Payment element is not initialized.");
    }

    try {
        const { amount, currency, billingDetails } = details;
        updateComponent(amount, currency);

        // Trigger form validation and wallet collection.
        const validation = await elements.submit();
        if (validation.error) {
            return { validationError: validation.error };
        }

        // Create the PaymentMethod using the details collected by the Payment Element.
        const result = await stripe.createPaymentMethod({
            elements: elements,
            params: {
                billing_details: billingDetails
            }
        });

        // After the user selects "Modify Details", the system triggers a validation process.
        // While this appears as a validation error, it is designed to allow the user to update
        // their input data. This mechanism ensures that users have the opportunity to review
        // and correct their details before final submission.
        if (result.error && result.error.code === "modify_bacs_debit_bank_details") {
            return { validationError: result.error.message };
        }

        if (result.error) {
            console.error("Stripe Component Error: ", result.error.message, result.error);
            return { error: result.error.message };
        }

        return { success: true, token: result.paymentMethod };
    } catch (error) {
        console.error("Failed to tokenize payment details:", error);
        throw new Error("Failed to tokenize payment details.");
    }
}

/**
 * Updates the Elements instance with new options.
 * @param amount - The new amount to set.
 * @param currency - The new currency to set.
 */
export function updateComponent(amount: number, currency: string): void {
    try {
        if (!paymentElement) {
            throw new Error("Stripe Payment Element is not initialized.");
        }
        if (elements) {
            elements.update({
                amount: amount,
                currency: currency.toLowerCase()
            });
        }
    } catch (error) {
        console.error("Failed to update Stripe Payment Element:", error);
        throw new Error("Failed to update Stripe Payment Element.");
    }
}

function getElementsOptions(details: any, allowedPaymentMethodTypes: string[]): ElementsOptions {

    const cardAppearance: AppearanceOptions = {
        theme: "stripe",
        rules: {
            '.Label': {
                opacity: 0,
                lineHeight: "0px"
            },
            '.Tab': {
                lineHeight: "100%"
            }
        }
    };

    const bankAppearance: AppearanceOptions = {
        theme: "stripe",
        labels: "above",
        rules: {
            ".Tab": {
                lineHeight: "100%"
            },

            ".Input": {
                borderColor: "#cccccc"
            },

            ".Input::placeholder": {
                color: "#FFFFFF00"
            },

            ".CheckboxInput": {
                borderColor: "#666666"
            },

            ".Label": {
                fontWeight: "600"
            },

            ".PickerItem": {
                borderColor: "#cccccc"
            },

            ".PickerItem--selected": {
                borderColor: "#cccccc"
            }
        }
    };

    const elementsOptions: ElementsOptions = {
        amount: 100,
        appearance: allowedPaymentMethodTypes[0] === "card" ? cardAppearance : bankAppearance,
        captureMethod: undefined,
        currency: details.currency.toLowerCase(),
        loader: "auto",
        mode: "payment",
        paymentMethodCreation: "manual",
        paymentMethodTypes: allowedPaymentMethodTypes,
        setupFutureUsage: details.setupFutureUsage ? "off_session" : "on_session"
    };

    return elementsOptions;
}

function getPaymentElementOptions(allowedPaymentMethodTypes: string[]): PaymentElementOptions {

    const paymentElementOptions: PaymentElementOptions = {
        layout: "tabs",
        paymentMethodOrder: allowedPaymentMethodTypes
    };

    // Add element options that are specific to the payment method.
    switch (allowedPaymentMethodTypes[0]) {
        case 'bacs_debit':
            // BACS: Set the default country option to 'GB'.
            paymentElementOptions.defaultValues = {
                billingDetails: {
                    address: { country: "GB" }
                }
            };
            break;
        case 'card':
            // Card: Hide the country and postal code input fields.
            paymentElementOptions.fields = {
                billingDetails: {
                    address: {
                        country: "never",
                        postalCode: "never"
                    }
                }
            };
            break;
    }

    return paymentElementOptions;
}

/**
 * Maps a payment method details type to its corresponding Stripe payment method type(s).
 * @param paymentMethodDetailsType - The type of the payment method details.
 * @returns An array of corresponding Stripe payment method types.
 * @throws An error if the payment method type is not implemented.
 */
export function getAllowedPaymentMethodTypes(paymentMethodDetailsType: string): string[] {
    const normalizedType = paymentMethodDetailsType.toLowerCase();

    if (normalizedType.startsWith("creditcard_")) return ["card"];

    switch (normalizedType) {
        case "directdebit_gbp":
            return ["bacs_debit"];
        case "directdebit_aud":
            return ["au_becs_debit"];
        case "directdebit_usd":
            return ["us_bank_account"];
        case "directdebit_eur":
            return ["sepa_debit"];
        case "directdebit_cad":
            return ["acss_debit"];
        default:
            throw new Error(`Payment method type not implemented: ${normalizedType}`);
    }
}

export function getRenderComponentActionDetails(paymentRequest: any, gateway: any) {
    return {
        accountId: gateway.stripeMetadata.stripeAccount,
        apiKey: gateway.stripeMetadata.publishableKey,
        amount: paymentRequest.transaction.amount.amount,
        currency: paymentRequest.transaction.amount.currency,
        paymentMethodType: paymentRequest.paymentMethod.paymentType,
        pspContainerId: "payment-element",
        sdkContainerId: paymentRequest.options.sdkContainerId,
        setupFutureUsage: true
    };
}

export function getTokenizationActionDetails(paymentRequest: any) {
    return {
        amount: parseInt(paymentRequest.transaction.amount.amount.toString(), 10) * 100,
        currency: paymentRequest.transaction.amount.currency,
        billing_details: {
            address: {
                city: paymentRequest.transaction.billingDetails.address.cityName,
                country: paymentRequest.transaction.billingDetails.address.countryCode,
                line1: paymentRequest.transaction.billingDetails.address.addressLines[0] ?? null,
                postal_code: paymentRequest.transaction.billingDetails.address.postalCode,
                state: paymentRequest.transaction.billingDetails.address.countrySubEntityCode
            },
            email: null,
            name: paymentRequest.transaction.billingDetails.name.fullName,
            phone: null
        }
    };
}